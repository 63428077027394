import { UserContext } from '@zola-helpers/client/dist/es/@types';
import PreauthFooterV3 from '@zola/zola-ui/src/components/PreAuthV3/PreauthFooterV3';
import { useLocationForVendorSRPLink } from '@zola/zola-ui/src/hooks/useLocationForVendorSRPLink/useLocationForVendorSRPLink';

import { getIsGuest } from 'libs/universal/utils/getIsGuest';
import {
  getIsInVendorSRPLinkExperiment,
  triggerSrpLinkExperimentViewed,
} from 'libs/universal/utils/srpLinkExperimentHelpers';

export const Footer = ({ userCtx }: { userCtx?: UserContext | null }) => {
  const weddingLocation = useLocationForVendorSRPLink({
    isInSRPExperiment: getIsInVendorSRPLinkExperiment(userCtx) || false,
  });
  return (
    <PreauthFooterV3
      isGuest={getIsGuest(userCtx)}
      weddingLocation={weddingLocation}
      marketplacePostAuthLinkOnClick={() => {
        triggerSrpLinkExperimentViewed(userCtx);
      }}
    />
  );
};

export default Footer;
